import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import ApiService from '../services/ApiService.js';
import { Table, Button, Spinner, Container, Pagination, Row, Col, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

const ShippingRateManagement = () => {
    const [rates, setRates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [sortField, setSortField] = useState('name');
    const [sortOrder, setSortOrder] = useState('asc');
    const [search, setSearch] = useState('');
    const pageSize = 10;
    const navigate = useNavigate();

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const params = { page: currentPage, pageSize, sortField, sortOrder, search };
            const data = await ApiService.get('/api/rate', params);
            setRates(data.rates || []);
            setTotalPages(data.totalPages || 1);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching shipping rates:', error);
            setLoading(false);
        }
    }, [currentPage, sortField, sortOrder, search]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleDeleteRate = async (rateId) => {
        try {
            await ApiService.delete(`/api/rate/${rateId}`);
            fetchData();
        } catch (error) {
            console.error('Error deleting shipping rate:', error);
        }
    };

    const handleSort = (field) => {
        const newSortOrder = (sortField === field && sortOrder === 'asc') ? 'desc' : 'asc';
        setSortField(field);
        setSortOrder(newSortOrder);
    };

    return (
        <Container style={{ fontSize: '0.8rem' }}>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h5 style={{ fontSize: '1rem', margin: 0 }}>Shipping Rate Management</h5>
                <Button variant="primary" size="sm" onClick={() => navigate('/admin/rates/upload')}>
                    Create Rate
                </Button>
            </div>

            <Form className="mb-3" onSubmit={(e) => { e.preventDefault(); fetchData(); }}>
                <Row>
                    <Col xs={9}>
                        <Form.Control
                            type="text"
                            placeholder="Search by name, carrier, or zone..."
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            size="sm"
                            style={{ fontSize: '0.8rem' }}
                        />
                    </Col>
                    <Col xs={3}>
                        <Button type="submit" variant="secondary" size="sm" className="w-100" style={{ fontSize: '0.8rem' }}>
                            Search
                        </Button>
                    </Col>
                </Row>
            </Form>

            {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '150px' }}>
                    <Spinner animation="border" size="sm" />
                </div>
            ) : (
                <>
                    <Table striped bordered hover size="sm" responsive style={{ fontSize: '0.8rem' }}>
                        <thead>
                            <tr>
                                <th onClick={() => handleSort('name')} style={{ cursor: 'pointer' }}>
                                    Name {sortField === 'name' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
                                </th>
                                <th onClick={() => handleSort('carrier')} style={{ cursor: 'pointer' }}>
                                    Carrier {sortField === 'carrier' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
                                </th>
                                <th onClick={() => handleSort('zone')} style={{ cursor: 'pointer' }}>
                                    Zone {sortField === 'zone' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
                                </th>
                                <th onClick={() => handleSort('weight')} style={{ cursor: 'pointer' }}>
                                    Weight {sortField === 'weight' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
                                </th>
                                <th onClick={() => handleSort('rate')} style={{ cursor: 'pointer' }}>
                                    Rate {sortField === 'rate' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
                                </th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rates.length > 0 ? (
                                rates.map((rate) => (
                                    <tr key={rate.id}>
                                        <td>{rate.name}</td>
                                        <td>{rate.carrier}</td>
                                        <td>{rate.zone}</td>
                                        <td>{rate.weight}</td>
                                        <td>{rate.rate}</td>
                                        <td>
                                            <Button
                                                variant="outline-primary"
                                                size="sm"
                                                className="me-1"
                                                style={{ fontSize: '0.8rem' }}
                                                onClick={() => navigate(`/admin/rates/${rate.id}`)}
                                            >
                                                <FontAwesomeIcon icon={faEdit} />
                                            </Button>
                                            <Button
                                                variant="outline-danger"
                                                size="sm"
                                                style={{ fontSize: '0.8rem' }}
                                                onClick={() => handleDeleteRate(rate.id)}
                                            >
                                                <FontAwesomeIcon icon={faTrashAlt} />
                                            </Button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="6" className="text-center" style={{ fontSize: '0.8rem' }}>
                                        No shipping rates found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>

                    <div className="d-flex justify-content-end">
                        <Pagination size="sm">
                            <Pagination.Prev
                                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                                disabled={currentPage === 1}
                            />
                            {[...Array(totalPages)].map((_, index) => (
                                <Pagination.Item
                                    key={index + 1}
                                    active={index + 1 === currentPage}
                                    onClick={() => setCurrentPage(index + 1)}
                                    style={{ fontSize: '0.8rem' }}
                                >
                                    {index + 1}
                                </Pagination.Item>
                            ))}
                            <Pagination.Next
                                onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                                disabled={currentPage === totalPages}
                            />
                        </Pagination>
                    </div>
                </>
            )}
        </Container>
    );
};

export default ShippingRateManagement;
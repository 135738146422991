import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals.js";
import { BrowserRouter } from "react-router-dom"; // Import the router

// Import Bootstrap, CoreUI, and Icons
import "bootstrap/dist/css/bootstrap.min.css";
import "@coreui/coreui/dist/css/coreui.min.css";
import "@coreui/icons/css/all.min.css";

// Import the App component
import App from "./App.js";

// Create the root element and render the App with the router
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </React.StrictMode>
);

// Measuring performance
reportWebVitals();
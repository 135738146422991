// ../services/UtilsService.mjs
// import dotenv from 'dotenv';

// Determine which .env file to load based on NODE_ENV
// const envFile = process.env.NODE_ENV === 'production' ? '.env.production' : '.env';
// // Load the environment variables from the appropriate file
// dotenv.config({ path: envFile });

class EnvironmentService {
    static getBaseUrl() {
        const env = process.env.NODE_ENV;

        if (env === 'development') {
            return 'http://localhost:3000';
        }

        if (env === 'production') {
            return 'https://rktindustries.com';
        }

        throw new Error(`Unknown environment: ${env}`);
    }

    static isDevelopment() {
        return process.env.NODE_ENV === 'development';
    }

    static isProduction() {
        return process.env.NODE_ENV === 'production';
    }
}

// Export the EnvironmentService class
export default EnvironmentService;
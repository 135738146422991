import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Spinner, Container, Row, Col, Breadcrumb } from 'react-bootstrap';
import ApiService from '../services/ApiService.js';  // Importing ApiService

const ShipmentLabelViewer = () => {
    const { trackingNumber } = useParams();  // Get tracking number from URL params
    const [labelSrc, setLabelSrc] = useState(null);  // For storing the data URL of the PDF or image
    const [loading, setLoading] = useState(true);  // Loading state
    const [error, setError] = useState(null);  // Error state

    useEffect(() => {
        const fetchLabel = async () => {
            try {
                // Make a request to your backend to retrieve the label
                const response = await ApiService.get(`api/shipment/${trackingNumber}/label`);

                // Get the base64 string and content type from the response
                const { content, contentType } = response;

                // Decode the base64 string and convert it into a Blob
                const byteCharacters = atob(content); // Decode base64
                const byteNumbers = new Array(byteCharacters.length).fill().map((_, i) => byteCharacters.charCodeAt(i));
                const byteArray = new Uint8Array(byteNumbers);

                // Create a Blob from the byte array
                const blob = new Blob([byteArray], { type: contentType });

                // Create a URL for the Blob and store it in state
                const url = URL.createObjectURL(blob);
                setLabelSrc({ url, contentType });
                setLoading(false);
            } catch (err) {
                console.error('Error fetching label:', err);
                setError(err.message);
                setLoading(false);
            }
        };

        fetchLabel();
    }, [trackingNumber]);

    // Determine the appropriate viewer based on the content type
    const renderLabel = () => {
        if (!labelSrc) return null;

        const { url, contentType } = labelSrc;

        // Render based on the file type
        if (contentType === 'application/pdf') {
            return (
                <iframe
                    src={url}
                    title="Shipment Label"
                    width="100%"
                    height="800px"
                    style={{ border: 'none' }}
                />
            );
        } else if (contentType.startsWith('image/')) {
            return <img src={url} alt="Shipment Label" style={{ maxWidth: '100%' }} />;
        } else {
            return <p>Unsupported label format: {contentType}</p>;
        }
    };

    return (
        <Container>
            <Row className="mb-4">
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin/shipments" }}>
                            Shipment Management
                        </Breadcrumb.Item>
                        <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/admin/shipments/${trackingNumber}` }}>
                            Shipment Details
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Shipment Label</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>

            <h2>Shipment Label for {trackingNumber}</h2>

            {loading ? (
                <Row className="justify-content-center align-items-center" style={{ height: '200px' }}>
                    <Spinner animation="border" />
                </Row>
            ) : error ? (
                <p>Error: {error}</p>
            ) : (
                renderLabel()
            )}
        </Container>
    );
};

export default ShipmentLabelViewer;
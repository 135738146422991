import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import EnvironmentService from './EnvironmentService.js';

// Create an Axios instance with default configuration
const api = axios.create({
    baseURL: EnvironmentService.isProduction() ? '/' : 'http://localhost:3000/', // Base URL for your API
    withCredentials: true, // Ensure credentials (like cookies) are sent
    headers: {
        'Content-Type': 'application/json',
    },
});

let isRefreshing = false;
let refreshSubscribers = [];

const onAccessTokenRefreshed = (newAccessToken) => {
    refreshSubscribers.forEach((callback) => callback(newAccessToken));
    refreshSubscribers = [];
};

const addRefreshSubscriber = (callback) => {
    refreshSubscribers.push(callback);
};

class ApiService {
    static async request(method, endpoint, data = null, params = {}) {
        const config = {
            method: method,
            url: endpoint,
            params: params, // Query parameters for GET requests
            headers: {
                'Content-Type': 'application/json', // Ensure the content type is set to JSON
            },
        };

        // Add data to the body only if method allows a request body
        if (['POST', 'PUT', 'PATCH', 'DELETE'].includes(method.toUpperCase()) && data) {
            config.data = data;
        }

        try {
            const response = await api(config);
            return response;
        } catch (error) {
            if (error.response && error.response.status === 401) {
                // Handle token refresh logic here
                if (!isRefreshing) {
                    isRefreshing = true;
                    try {
                        await ApiService.post('/auth/oauth/login-refresh', {});

                        // Retry the original request with the new token
                        return await api.request({
                            ...config
                        });
                    } catch (refreshError) {
                        console.error('Token refresh failed:', refreshError);
                        ApiService.redirectToLogin();
                    } finally {
                        isRefreshing = false;
                    }
                } else {
                    return new Promise((resolve) => {
                        addRefreshSubscriber(async (newAccessToken) => {
                            resolve(
                                await api.request({
                                    ...config,
                                    headers: {
                                        ...config.headers,
                                        Authorization: `Bearer ${newAccessToken}`,
                                    },
                                })
                            );
                        });
                    });
                }
            } else {
                ApiService.handleError(error);
                throw error; // Re-throw the error so it can be handled elsewhere
            }
        }
    }

    static async get(endpoint, params = {}) {
        const response = await ApiService.request('GET', endpoint, null, params);
        return response.data;
    }

    static async post(endpoint, data) {
        const response = await ApiService.request('POST', endpoint, data);
        return response.data;
    }

    static async put(endpoint, data) {
        const response = await ApiService.request('PUT', endpoint, data);
        return response.data;
    }

    static async delete(endpoint) {
        const response = await ApiService.request('DELETE', endpoint, '{}');
        return response.data;
    }

    static redirectToLogin() {
        if (EnvironmentService.isDevelopment()) {
            window.location.href = 'http://localhost:3001/admin/login';
        } else {
            window.location.href = '/login';
        }
    }

    static handleError(error) {
        let errorMessage = '';

        if (error.response) {
            switch (error.response.status) {
                case 401:
                    errorMessage = 'Unauthorized';
                    ApiService.redirectToLogin();
                    break;
                case 500:
                    errorMessage = 'Unknown error occurred on the server';
                    break;
                case 400:
                    errorMessage = error.response.data?.message || 'Bad request';
                    break;
                default:
                    errorMessage = `Error ${error.response.status}: ${error.response.data?.message || 'An error occurred'}`;
            }
        } else {
            errorMessage = error.message || 'An error occurred';
        }

        console.error('API Error:', errorMessage);
    }
}

export default ApiService;
import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import ApiService from '../services/ApiService.js';
import { Breadcrumb, Spinner, Container, Row, Col, Form, Button, Alert, Accordion } from 'react-bootstrap';
import CustomerDropdown from './CustomerDropdown.js';
import IntegrationConfiguration from '../utils/IntegrationConfiguration.js';
import CountryDropdown from './CountryDropdown.js';

const IntegrationForm = ({ customerId, apiUserId, onSave }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [integration, setIntegration] = useState({
        customer_id: customerId || '',
        integration_type: '',
        integration_details: {},
        api_user_id: apiUserId || '', // Added for API User
    });
    const [customIntegrationData, setCustomIntegrationData] = useState({});
    const [apiUsers, setApiUsers] = useState([]); // To store API User list
    const [loading, setLoading] = useState(id !== 'create' && !customerId);
    const [error, setError] = useState('');
    const [fieldErrors, setFieldErrors] = useState({});
    const integrationTypes = IntegrationConfiguration.getAllKeys();

    useEffect(() => {
        if (id !== 'create' && !customerId) {
            const fetchIntegration = async () => {
                try {
                    const data = await ApiService.get(`api/integration/${id}`);
                    setIntegration({
                        customer_id: data.customer_id || '',
                        integration_type: data.integration_type || '',
                        integration_details: data.integration_details || {},
                        api_user_id: data.api_user_id || '', // Added for API User
                    });

                    const integrationType = data.integration_type;
                    const integrationDetail = IntegrationConfiguration.getIntegrationByKey(integrationType);

                    if (integrationDetail) {
                        setCustomIntegrationData(
                            data.integration_details || { ...integrationDetail.setupFields }
                        );
                    } else {
                        setCustomIntegrationData({});
                    }
                    setLoading(false);
                } catch (error) {
                    console.error('Error fetching integration details:', error);
                    setLoading(false);
                }
            };

            fetchIntegration();
        }

        // Fetch API Users for the dropdown
        const fetchApiUsers = async () => {
            try {
                const users = await ApiService.get('api/user');
                setApiUsers(users.apiUsers || []);
            } catch (error) {
                console.error('Error fetching API users:', error);
            }
        };

        fetchApiUsers();
    }, [id]);

    const handleCustomerChange = (customerId) => {
        setIntegration((prev) => ({
            ...prev,
            customer_id: customerId || '',
        }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setIntegration((prev) => ({
            ...prev,
            [name]: value || '',
        }));
    };

    const handleCustomFieldChange = (name, value) => {
        setCustomIntegrationData((prev) => ({
            ...prev,
            [name]: value || '',
        }));
        validateField(name, value || '');
    };

    const handleNestedFieldChange = (section, field, value) => {
        setCustomIntegrationData((prev) => ({
            ...prev,
            [section]: {
                ...prev[section],
                [field]: value || '',
            },
        }));
        validateField(field, value || '', section);
    };

    const formatFieldName = (name) =>
        name.replace(/([A-Z])/g, ' $1').replace(/\b\w/g, (char) => char.toUpperCase());

    const validateField = (name, value, section = null) => {
        const defaultFields =
            IntegrationConfiguration.getIntegrationByKey(integration.integration_type)?.setupFields || {};
        const fieldConfig = section ? defaultFields[section]?.[name] : defaultFields[name];
        const errorKey = section ? `${section}.${name}` : name;
        const formattedFieldName = section
            ? `${formatFieldName(section)} ${formatFieldName(name)}`
            : formatFieldName(name);

        let errorMessage = '';

        if (fieldConfig?.required && !value) {
            errorMessage = `${formattedFieldName} is required`;
        } else if (!errorMessage && fieldConfig?.type === 'number' && isNaN(value)) {
            errorMessage = `${formattedFieldName} must be a number`;
        } else if (!errorMessage && fieldConfig?.validation?.type === 'regex' && fieldConfig.validation.pattern) {
            const regex = new RegExp(fieldConfig.validation.pattern);
            if (!regex.test(value)) {
                errorMessage = `${formattedFieldName} is invalid`;
            }
        }

        if (errorMessage) {
            setFieldErrors((prev) => ({
                ...prev,
                [errorKey]: errorMessage,
            }));
        } else {
            setFieldErrors((prev) => {
                const updatedErrors = { ...prev };
                delete updatedErrors[errorKey];
                return updatedErrors;
            });
        }
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setError('');

        let hasErrors = false;
        const defaultFields =
            IntegrationConfiguration.getIntegrationByKey(integration.integration_type)?.setupFields || {};
        const newErrors = {};

        Object.entries(defaultFields).forEach(([section, fields]) => {
            if (typeof fields === 'object' && !Array.isArray(fields)) {
                Object.entries(fields).forEach(([field, config]) => {
                    const value = customIntegrationData[section]?.[field] || '';
                    const errorKey = `${section}.${field}`;
                    const formattedFieldName = `${formatFieldName(section)} ${formatFieldName(field)}`;

                    if (config.required && !value) {
                        newErrors[errorKey] = `${formattedFieldName} is required`;
                        hasErrors = true;
                    }
                });
            } else {
                const value = customIntegrationData[section] || '';
                const formattedFieldName = formatFieldName(section);

                if (fields.required && !value) {
                    newErrors[section] = `${formattedFieldName} is required`;
                    hasErrors = true;
                }
            }
        });

        if (hasErrors) {
            setFieldErrors(newErrors);
            return;
        }

        setFieldErrors({});

        let integrationDetailsForSubmission = {};
        if (integration.integration_type) {
            integrationDetailsForSubmission = { ...customIntegrationData };
        }

        try {
            if (id !== 'create' && !customerId) {
                await ApiService.put(`api/integration/${id}`, {
                    customer_id: integration.customer_id,
                    integration_type: integration.integration_type,
                    integration_details: integrationDetailsForSubmission,
                    api_user_id: integration.api_user_id || '', // Include API User in submission
                });
            } else {
                await ApiService.post('api/integration', {
                    customer_id: integration.customer_id,
                    integration_type: integration.integration_type,
                    integration_details: integrationDetailsForSubmission,
                    api_user_id: integration.api_user_id || '', // Include API User in submission
                });
            }
            if (!customerId) {
                navigate('/admin/integrations');
            }
            else {
                onSave();
            }
        } catch (error) {
            console.error('Error saving integration:', error);
        
            const errorMessage = error?.response?.data?.message || error.message || 'An unexpected error occurred.';
            setError('An error occurred while saving the integration. ' + errorMessage);
        }
    };

    const renderField = (key, value = '', section = null) => {
        const errorKey = section ? `${section}.${key}` : key;
        const isError = !!fieldErrors[errorKey];
    
        // Convert non-string values to empty strings if necessary
        const fieldValue = typeof value === 'string' ? value : '';
    
        return (
            <Col md={6} key={key}>
                <Form.Group className="mb-2">
                    <Form.Label className="form-label-sm">
                        {formatFieldName(key)}
                        {IntegrationConfiguration.getIntegrationByKey(integration.integration_type)
                            ?.setupFields[section]?.[key]?.required && (
                                <span className="text-danger"> *</span>
                            )}
                    </Form.Label>
                    <Form.Control
                        size="sm"
                        type="text"
                        name={key}
                        value={fieldValue}
                        onChange={(e) =>
                            section
                                ? handleNestedFieldChange(section, key, e.target.value)
                                : handleCustomFieldChange(key, e.target.value)
                        }
                        isInvalid={isError}
                    />
                    {isError && <Form.Control.Feedback type="invalid">{fieldErrors[errorKey]}</Form.Control.Feedback>}
                </Form.Group>
            </Col>
        );
    };

    return (
        <Container>
            {!customerId ? (
                <Breadcrumb className="mb-3" style={{ fontSize: '0.85rem' }}>
                    <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/admin/integrations' }}>
                        Integration Management
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>{id === 'create' ? 'Create Integration' : 'Edit Integration'}</Breadcrumb.Item>
                </Breadcrumb>
                ) : null
            }

            <h5 className="mb-3">{id === 'create' || customerId ? 'Create Integration' : 'Edit Integration'}</h5>
            {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '150px' }}>
                    <Spinner animation="border" size="sm" />
                </div>
            ) : (
                <Form onSubmit={handleFormSubmit}>
                    <Row>
                        <Col>
                            <Form.Group className="mb-2">
                                <CustomerDropdown
                                    onChange={handleCustomerChange}
                                    selectedCustomerId={integration.customer_id}
                                />
                            </Form.Group>

                            <Form.Group className="mb-2">
                                <Form.Label className="form-label-sm">Integration Type</Form.Label>
                                <Form.Select
                                    name="integration_type"
                                    value={integration.integration_type || ''}
                                    onChange={handleInputChange}
                                    required
                                    size="sm"
                                >
                                    <option value="" disabled>
                                        Select Integration Type
                                    </option>
                                    {integrationTypes.map((type) => (
                                        <option key={type} value={type}>
                                            {IntegrationConfiguration.getIntegrationByKey(type)?.name || type}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="mb-2">
                                <Form.Label className="form-label-sm">API User</Form.Label>
                                <Form.Select
                                    name="api_user_id"
                                    value={integration.api_user_id || ''}
                                    onChange={handleInputChange}
                                    size="sm"
                                >
                                    <option value="" disabled>
                                        Select API User
                                    </option>
                                    {apiUsers.map((user) => (
                                        <option key={user.api_user_id} value={user.api_user_id}>
                                            {user.name} ({user.email})
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>

                            <Accordion>
                                {Object.entries(
                                    IntegrationConfiguration.getIntegrationByKey(integration.integration_type)
                                        ?.setupFields || {}
                                ).map(([section, value], idx) => (
                                    <Accordion.Item eventKey={idx.toString()} key={section}>
                                        <Accordion.Header>{formatFieldName(section)}</Accordion.Header>
                                        <Accordion.Body>
                                            <Row>
                                                {typeof value === 'object' && !Array.isArray(value)
                                                    ? Object.entries(value).map(([field, fieldValue]) =>
                                                          renderField(
                                                              field,
                                                              customIntegrationData[section]?.[field] || fieldValue,
                                                              section
                                                          )
                                                      )
                                                    : renderField(section, customIntegrationData[section] || value)}
                                            </Row>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                ))}
                            </Accordion>

                            {error && (
                                <Alert variant="danger" className="mt-2" style={{ fontSize: '0.85rem' }}>
                                    {error}
                                </Alert>
                            )}

                            <div className="d-flex justify-content-end mt-2">
                                <Button variant="primary" type="submit" size="sm">
                                    {id && !customerId ? 'Update Integration' : 'Create Integration'}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            )}
        </Container>
    );
};

export default IntegrationForm;
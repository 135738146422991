import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ApiService from '../services/ApiService.js';
import EnvironmentService from '../services/EnvironmentService.js';

const AdminLogin = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleLogin = async (e) => {
        e.preventDefault();
        setError(''); // Clear previous errors

        try {
            // Make a POST request to the auth/login endpoint
            const response = await ApiService.post('auth/login', {
                redirect_uri: '/admin',
                username,
                password,
            });

            // Extract the redirect URI from the response
            const { redirect_uri } = response;

            // Redirect to the provided URI
            const finalRedirectUri = EnvironmentService.isDevelopment()
                ? `http://localhost:3001${redirect_uri}`
                : redirect_uri;

            window.location.href = finalRedirectUri;
        } catch (error) {
            if (error.response && error.response.status === 401) {
                setError('Invalid username or password');
            } else {
                setError('An error occurred. Please try again.');
            }
        }
    };

    return (
        <div className="d-flex justify-content-center align-items-center vh-100 bg-light">
            <div className="card shadow-lg p-4" style={{ width: '400px' }}>
                <h2 className="card-title text-center mb-4">RKT Industries</h2>
                <form onSubmit={handleLogin}>
                    <div className="mb-3">
                        <label htmlFor="username" className="form-label">Username</label>
                        <input
                            type="text"
                            id="username"
                            className="form-control"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="password" className="form-label">Password</label>
                        <input
                            type="password"
                            id="password"
                            className="form-control"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    {error && <p className="text-danger">{error}</p>}
                    <div className="d-grid mb-3">
                        <button type="submit" className="btn btn-primary">Login</button>
                    </div>
                </form>
                <div className="text-center">
                    <Link to="/admin/signup">Don't have an account? Signup</Link>
                </div>
            </div>
        </div>
    );
};

export default AdminLogin;
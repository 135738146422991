import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import ApiService from '../services/ApiService.js';
import { Breadcrumb, Spinner, Container, Row, Col, Form, Button } from 'react-bootstrap';

const AdminUserDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [user, setUser] = useState({
        username: '',
        email: '',
        roles: [],
        password: '',
    });
    const [loading, setLoading] = useState(id !== 'create');
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        if (id !== 'create') {
            const fetchUser = async () => {
                try {
                    const data = await ApiService.get(`api/admin/user/${id}`);
                    setUser(data);
                    setLoading(false);
                } catch (error) {
                    console.error('Error fetching user details:', error);
                    setLoading(false);
                }
            };
            fetchUser();
        }
    }, [id]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUser((prevUser) => ({
            ...prevUser,
            [name]: value,
        }));
    };

    const handleSave = async () => {
        setSaving(true);
        try {
            const userData = { ...user };
            if (!user.password) {
                delete userData.password;
            }
            id === 'create'
                ? await ApiService.post('api/admin/user', user)
                : await ApiService.put(`api/admin/user/${id}`, userData);
            setSaving(false);
            navigate('/admin/admin_users');
        } catch (error) {
            console.error('Error saving user:', error);
            setSaving(false);
        }
    };

    return (
        <Container style={{ fontSize: '0.85rem' }}>
            <Row className="mb-3">
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin/admin_users" }}>
                            Admin User Management
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>{id === 'create' ? 'Create Admin User' : 'Admin User Details'}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>

            <h2 style={{ fontSize: '1.5rem' }}>{id === 'create' ? 'Create Admin User' : 'Admin User Details'}</h2>

            {loading ? (
                <Row className="justify-content-center align-items-center" style={{ height: '150px' }}>
                    <Spinner animation="border" size="sm" />
                </Row>
            ) : (
                <Form>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-2">
                                <Form.Label>Username</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="username"
                                    value={user.username}
                                    onChange={handleInputChange}
                                    placeholder="Enter username"
                                    size="sm"
                                    required
                                />
                            </Form.Group>
                            <Form.Group className="mb-2">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    value={user.email}
                                    onChange={handleInputChange}
                                    placeholder="Enter email"
                                    size="sm"
                                    required
                                />
                            </Form.Group>

                            {id === 'create' && (
                                <Form.Group className="mb-2">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        name="password"
                                        value={user.password}
                                        onChange={handleInputChange}
                                        placeholder="Enter password"
                                        size="sm"
                                        required
                                    />
                                </Form.Group>
                            )}

                            <h3 style={{ fontSize: '1.2rem' }}>Roles</h3>
                            <Form.Group className="mb-2">
                                <Form.Control
                                    type="text"
                                    name="roles"
                                    value={Array.isArray(user?.roles) ? user.roles.join(', ') : ''}
                                    onChange={(e) =>
                                        setUser((prevUser) => ({
                                            ...prevUser,
                                            roles: e.target.value.split(',').map((role) => role.trim()),
                                        }))
                                    }
                                    placeholder="Enter roles, separated by commas"
                                    size="sm"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Button variant="primary" size="sm" onClick={handleSave} disabled={saving}>
                        {saving ? 'Saving...' : 'Save'}
                    </Button>
                </Form>
            )}
        </Container>
    );
};

export default AdminUserDetails;
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import ApiService from '../services/ApiService.js';
import {
    Breadcrumb,
    Spinner,
    Container,
    Button,
    Form,
    InputGroup,
    Card,
    ToastContainer,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomerDropdown from '../components/CustomerDropdown.js';

const ApiUserDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [apiUser, setApiUser] = useState({
        name: '',
        clientId: '',
        prodClientSecret: '',
        prodAuthorizationCode: '',
        devClientSecret: '',
        devAuthorizationCode: '',
        customerId: '',
    });
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(id !== 'create');
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        const fetchCustomers = async () => {
            try {
                const data = await ApiService.get('api/customer');
                setCustomers(data);
            } catch (error) {
                console.error('Error fetching customers:', error);
                toast.error(`Failed to load customers: ${error.response?.data?.message || error.message}`);
            }
        };

        fetchCustomers();
    }, []);

    useEffect(() => {
        if (id !== 'create') {
            const fetchApiUser = async () => {
                try {
                    const data = await ApiService.get(`api/user/${id}`);
                    setApiUser({
                        name: data.apiUser.name,
                        clientId: data.apiUser.client_id,
                        prodClientSecret: data.apiUser.prod_client_secret,
                        prodAuthorizationCode: data.apiUser.prod_authorization_code || '',
                        devClientSecret: data.apiUser.dev_client_secret,
                        devAuthorizationCode: data.apiUser.dev_authorization_code || '',
                        customerId: data.apiUser.customer_id,
                    });
                    setLoading(false);
                } catch (error) {
                    console.error('Error fetching API user details:', error);
                    setLoading(false);
                    toast.error(`Failed to load API user details: ${error.response?.data?.message || error.message}`);
                }
            };

            fetchApiUser();
        }
    }, [id]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setApiUser((prev) => ({ ...prev, [name]: value }));
    };

    const handleCopy = (value, message) => {
        navigator.clipboard.writeText(value).then(() => {
            setCopied(true);
            toast.success(`${message} copied to clipboard!`);
            setTimeout(() => setCopied(false), 2000);
        });
    };

    const toCamelCase = (str) => {
        return str
            .replace(/([-_][a-z])/g, (group) =>
                group.toUpperCase().replace('-', '').replace('_', '')
            )
            .replace(/^[A-Z]/, (firstLetter) => firstLetter.toLowerCase());
    }

    const handleRefresh = async (type, environment) => {
        const endpoint = `api/user/${id}/refresh${environment}${type}`;
        try {
            const { [toCamelCase(type)]: updatedValue } = await ApiService.post(endpoint);
            setApiUser((prev) => ({
                ...prev,
                [`${environment.toLowerCase()}${type}`]: updatedValue,
            }));
            toast.success(`${environment} ${type.replace(/([A-Z])/g, ' $1')} refreshed successfully!`);
        } catch (error) {
            console.error(`Error refreshing ${environment} ${type}:`, error);
            toast.error(`Failed to refresh ${environment} ${type}: ${error.response?.data?.message || error.message}`);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!apiUser.name || !apiUser.customerId) {
            toast.error('All fields are required.');
            return;
        }

        try {
            if (id !== 'create') {
                await ApiService.put(`api/user/${id}`, apiUser);
                toast.success('API user updated successfully!');
            } else {
                await ApiService.post('api/user', apiUser);
                toast.success('API user created successfully!');
            }
            navigate('/admin/api_users');
        } catch (error) {
            console.error('Error saving API user:', error);
            toast.error(`An error occurred while saving the API user: ${error.response?.data?.error || error.message}`);
        }
    };

    return (
        <Container style={{ fontSize: '0.85rem' }}>
            <ToastContainer />
            <Breadcrumb className="mb-3">
                <Breadcrumb.Item onClick={() => navigate('/admin/api_users')}>API User Management</Breadcrumb.Item>
                <Breadcrumb.Item active>{id ? 'Edit API User' : 'Create API User'}</Breadcrumb.Item>
            </Breadcrumb>

            <h5 className="mb-3">{id ? 'Edit API User' : 'Create API User'}</h5>

            {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '150px' }}>
                    <Spinner animation="border" size="sm" />
                </div>
            ) : (
                <>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label>Customer</Form.Label>
                            <CustomerDropdown
                                selectedCustomerId={apiUser.customerId}
                                onChange={(customerId) => setApiUser((prev) => ({ ...prev, customerId }))}
                                customers={customers}
                            />
                        </Form.Group>

                        <Form.Group className="mb-2">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={apiUser.name || ''}
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Group>

                        <Card className="mb-3">
                            <Card.Header>Client ID</Card.Header>
                            <Card.Body>
                                <InputGroup>
                                    <Form.Control
                                        type="text"
                                        value={apiUser.clientId || ''}
                                        readOnly
                                    />
                                    <Button
                                        variant="outline-secondary"
                                        onClick={() => handleCopy(apiUser.clientId, 'Client ID')}
                                    >
                                        <FontAwesomeIcon icon={faCopy} />
                                    </Button>
                                </InputGroup>
                            </Card.Body>
                        </Card>

                        {id !== 'create' && (
                            <div>
                                {/* Production API Credentials */}
                                <Card className="mb-3">
                                    <Card.Header>Production API Credentials</Card.Header>
                                    <Card.Body>
                                        <Form.Group className="mb-2">
                                            <Form.Label>Client Secret</Form.Label>
                                            <InputGroup>
                                                <Form.Control
                                                    type="text"
                                                    value={apiUser.prodClientSecret || ''}
                                                    readOnly
                                                />
                                                <Button
                                                    variant="outline-secondary"
                                                    onClick={() => handleRefresh('ClientSecret', 'Prod')}
                                                >
                                                    <FontAwesomeIcon icon={faSyncAlt} />
                                                </Button>
                                                <Button
                                                    variant="outline-secondary"
                                                    onClick={() => handleCopy(apiUser.prodClientSecret, 'Production Client Secret')}
                                                >
                                                    <FontAwesomeIcon icon={faCopy} />
                                                </Button>
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group className="mb-2">
                                            <Form.Label>Authorization Code</Form.Label>
                                            <InputGroup>
                                                <Form.Control
                                                    type="text"
                                                    value={apiUser.prodAuthorizationCode || ''}
                                                    readOnly
                                                />
                                                <Button
                                                    variant="outline-secondary"
                                                    onClick={() => handleRefresh('AuthorizationCode', 'Prod')}
                                                >
                                                    <FontAwesomeIcon icon={faSyncAlt} />
                                                </Button>
                                                <Button
                                                    variant="outline-secondary"
                                                    onClick={() => handleCopy(apiUser.prodAuthorizationCode, 'Production Authorization Code')}
                                                >
                                                    <FontAwesomeIcon icon={faCopy} />
                                                </Button>
                                            </InputGroup>
                                        </Form.Group>
                                    </Card.Body>
                                </Card>

                                {/* Development API Credentials */}
                                <Card className="mb-3">
                                    <Card.Header>Development API Credentials</Card.Header>
                                    <Card.Body>
                                        <Form.Group className="mb-2">
                                            <Form.Label>Client Secret</Form.Label>
                                            <InputGroup>
                                                <Form.Control
                                                    type="text"
                                                    value={apiUser.devClientSecret || ''}
                                                    readOnly
                                                />
                                                <Button
                                                    variant="outline-secondary"
                                                    onClick={() => handleRefresh('ClientSecret', 'Dev')}
                                                >
                                                    <FontAwesomeIcon icon={faSyncAlt} />
                                                </Button>
                                                <Button
                                                    variant="outline-secondary"
                                                    onClick={() => handleCopy(apiUser.devClientSecret, 'Development Client Secret')}
                                                >
                                                    <FontAwesomeIcon icon={faCopy} />
                                                </Button>
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group className="mb-2">
                                            <Form.Label>Authorization Code</Form.Label>
                                            <InputGroup>
                                                <Form.Control
                                                    type="text"
                                                    value={apiUser.devAuthorizationCode || ''}
                                                    readOnly
                                                />
                                                <Button
                                                    variant="outline-secondary"
                                                    onClick={() => handleRefresh('AuthorizationCode', 'Dev')}
                                                >
                                                    <FontAwesomeIcon icon={faSyncAlt} />
                                                </Button>
                                                <Button
                                                    variant="outline-secondary"
                                                    onClick={() => handleCopy(apiUser.devAuthorizationCode, 'Development Authorization Code')}
                                                >
                                                    <FontAwesomeIcon icon={faCopy} />
                                                </Button>
                                            </InputGroup>
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                            </div>
                        )}

                        <div className="d-flex justify-content-end">
                            <Button variant="primary" type="submit">
                                {id ? 'Update' : 'Create'}
                            </Button>
                        </div>
                    </Form>
                </>
            )}
        </Container>
    );
};

export default ApiUserDetails;
import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import ApiService from '../services/ApiService.js';
import { Breadcrumb, Spinner, Container, Row, Col, Form, Button } from 'react-bootstrap';

const CustomerDetails = () => {
    const { id } = useParams();
    const [customer, setCustomer] = useState({
        name: '',
        email: '',
        address: {
            street1: '',
            street2: '',
            street3: '',
            city: '',
            stateOrProvince: '',
            postalCode: '',
            country: ''
        },
        billing_address: {
            street1: '',
            street2: '',
            street3: '',
            city: '',
            stateOrProvince: '',
            postalCode: '',
            country: ''
        }
    });
    const [sameAsAddress, setSameAsAddress] = useState(false);
    const [loading, setLoading] = useState(id && id !== 'create');
    const [saving, setSaving] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (id && id !== 'create') {
            const fetchCustomer = async () => {
                try {
                    const data = await ApiService.get(`api/customer/${id}`);
                    setCustomer(data);
                    setLoading(false);
                } catch (error) {
                    console.error('Error fetching customer details:', error);
                    setLoading(false);
                }
            };
            fetchCustomer();
        }
    }, [id]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCustomer({ ...customer, [name]: value });
    };

    const handleAddressChange = (e, addressType) => {
        const { name, value } = e.target;
        setCustomer((prev) => ({
            ...prev,
            [addressType]: { ...prev[addressType], [name]: value }
        }));
        if (sameAsAddress) {
            setCustomer((prev) => ({
                ...prev,
                billing_address: { ...prev.billing_address, [name]: value }
            }));
        }
    };

    const handleSameAsAddressChange = (e) => {
        setSameAsAddress(e.target.checked);
        if (e.target.checked) {
            setCustomer((prev) => ({
                ...prev,
                billing_address: { ...prev.address }
            }));
        }
    };

    const handleSave = async () => {
        setSaving(true);
        try {
            if (!id || id === 'create') {
                await ApiService.post('api/customer', customer);
            } else {
                await ApiService.put(`api/customer/${id}`, customer);
            }
            setSaving(false);
            navigate('/admin/customers');
        } catch (error) {
            console.error('Error saving customer details:', error);
            setSaving(false);
        }
    };

    return (
        <Container style={{ fontSize: '0.85rem' }}>
            <Breadcrumb className="mb-3">
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin/customers" }}>
                    Customer Management
                </Breadcrumb.Item>
                <Breadcrumb.Item active>{id === 'create' ? 'Create Customer' : 'Customer Details'}</Breadcrumb.Item>
            </Breadcrumb>

            <div className="d-flex justify-content-between align-items-center mb-2">
                <h2 style={{ fontSize: '1.5rem' }}>{id === 'create' ? 'Create Customer' : 'Customer Details'}</h2>
                <Button size="sm" variant="primary" onClick={handleSave} disabled={saving}>
                    {saving ? 'Saving...' : 'Save'}
                </Button>
            </div>

            {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '150px' }}>
                    <Spinner animation="border" size="sm" />
                </div>
            ) : (
                <Form>
                    <Row className="mb-3">
                        <Col md={6}>
                            <Form.Group className="mb-2">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    size="sm"
                                    type="text"
                                    name="name"
                                    value={customer.name || ''}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-2">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    size="sm"
                                    type="email"
                                    name="email"
                                    value={customer.email || ''}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <h5>Address</h5>
                    <Row>
                        {['street1', 'street2', 'street3', 'city', 'stateOrProvince', 'postalCode', 'country'].map((field, idx) => (
                            <Col md={4} key={field}>
                                <Form.Group className="mb-2">
                                    <Form.Label>{field.replace(/([A-Z])/g, ' $1')}</Form.Label>
                                    <Form.Control
                                        size="sm"
                                        type="text"
                                        name={field}
                                        value={customer.address?.[field] || ''}
                                        onChange={(e) => handleAddressChange(e, 'address')}
                                    />
                                </Form.Group>
                            </Col>
                        ))}
                    </Row>

                    <Form.Group className="mb-3" controlId="sameAsAddressCheckbox">
                        <Form.Check
                            type="checkbox"
                            label="Billing Address is same as Address"
                            checked={sameAsAddress}
                            onChange={handleSameAsAddressChange}
                        />
                    </Form.Group>

                    <h5>Billing Address</h5>
                    <Row>
                        {['street1', 'street2', 'street3', 'city', 'stateOrProvince', 'postalCode', 'country'].map((field) => (
                            <Col md={4} key={`billing_${field}`}>
                                <Form.Group className="mb-2">
                                    <Form.Label>{field.replace(/([A-Z])/g, ' $1')}</Form.Label>
                                    <Form.Control
                                        size="sm"
                                        type="text"
                                        name={field}
                                        value={customer.billing_address?.[field] || ''}
                                        onChange={(e) => handleAddressChange(e, 'billing_address')}
                                        disabled={sameAsAddress}
                                    />
                                </Form.Group>
                            </Col>
                        ))}
                    </Row>
                </Form>
            )}
        </Container>
    );
};

export default CustomerDetails;
import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import ApiService from '../services/ApiService.js'; 
import { Spinner, Container, Table, Button, Pagination, Form, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

const AdminUserDetails = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [sortField, setSortField] = useState('username');
    const [sortOrder, setSortOrder] = useState('asc');
    const [search, setSearch] = useState('');
    const pageSize = 10;
    const navigate = useNavigate();

    const fetchUsers = useCallback(async () => {
        setLoading(true);
        try {
            const params = {
                page: currentPage,
                pageSize,
                sortField,
                sortOrder,
                search,
            };
            const data = await ApiService.get('api/admin/user', params);
            setUsers(data.users || []);
            setTotalPages(data.totalPages || 1);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching admin users:', error);
            setLoading(false);
        }
    }, [currentPage, sortField, sortOrder, search]);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    const handleSort = (field) => {
        const newSortOrder = (sortField === field && sortOrder === 'asc') ? 'desc' : 'asc';
        setSortField(field);
        setSortOrder(newSortOrder);
    };

    const handleDeleteUser = async (userId) => {
        try {
            await ApiService.put(`api/admin/user/${userId}/inactive`);
            fetchUsers();
        } catch (error) {
            console.error('Error marking user as inactive:', error);
        }
    };

    return (
        <Container style={{ fontSize: '0.85rem' }}>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h2 style={{ fontSize: '1.5rem' }}>Admin User Management</h2>
                <Button variant="primary" size="sm" onClick={() => navigate('/admin/admin_users/create')}>
                    Create Admin User
                </Button>
            </div>

            {/* Search Form */}
            <Form className="mb-2" onSubmit={(e) => { e.preventDefault(); fetchUsers(); }}>
                <Row className="align-items-center">
                    <Col xs={10}>
                        <Form.Control
                            type="text"
                            placeholder="Search by username or email..."
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            size="sm"
                            style={{ fontSize: '0.85rem' }}
                        />
                    </Col>
                    <Col xs={2}>
                        <Button type="submit" variant="secondary" size="sm" className="w-100">Search</Button>
                    </Col>
                </Row>
            </Form>

            {/* Loading Spinner */}
            {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '150px' }}>
                    <Spinner animation="border" size="sm" />
                </div>
            ) : (
                <Table striped bordered hover size="sm" style={{ fontSize: '0.85rem' }}>
                    <thead>
                        <tr>
                            <th onClick={() => handleSort('username')}>
                                Username {sortField === 'username' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
                            </th>
                            <th onClick={() => handleSort('email')}>
                                Email {sortField === 'email' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
                            </th>
                            <th>Roles</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.length > 0 ? (
                            users.map((user) => (
                                <tr key={user.admin_user_id}>
                                    <td>{user.username}</td>
                                    <td>{user.email}</td>
                                    <td>{Array.isArray(user?.roles) ? user.roles.join(', ') : ''}</td>
                                    <td>
                                        <Button variant="outline-primary" size="sm" onClick={() => navigate(`/admin/admin_users/${user.admin_user_id}`)}>
                                            <FontAwesomeIcon icon={faEdit} />
                                        </Button>
                                        <Button variant="outline-danger" size="sm" className="ms-1" onClick={() => handleDeleteUser(user.admin_user_id)}>
                                            <FontAwesomeIcon icon={faTrashAlt} />
                                        </Button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="4" className="text-center">No users found</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            )}

            {/* Pagination */}
            <div className="d-flex justify-content-end">
                <Pagination size="sm">
                    <Pagination.Prev
                        onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                        disabled={currentPage === 1}
                    />
                    {[...Array(totalPages)].map((_, index) => (
                        <Pagination.Item
                            key={index + 1}
                            active={index + 1 === currentPage}
                            onClick={() => setCurrentPage(index + 1)}
                        >
                            {index + 1}
                        </Pagination.Item>
                    ))}
                    <Pagination.Next
                        onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                        disabled={currentPage === totalPages}
                    />
                </Pagination>
            </div>
        </Container>
    );
};

export default AdminUserDetails;
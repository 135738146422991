import React, { useState, useEffect, useCallback } from 'react';
import ApiService from '../services/ApiService.js';
import { useNavigate } from 'react-router-dom';
import { Button, Table, Spinner, Pagination, Form, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

const CustomerManagement = () => {
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [sortField, setSortField] = useState('name');
    const [sortOrder, setSortOrder] = useState('asc');
    const [search, setSearch] = useState('');
    const pageSize = 10;

    const navigate = useNavigate();

    const fetchCustomers = useCallback(async () => {
        setLoading(true);
        try {
            const params = { page: currentPage, pageSize, sortField, sortOrder, search };
            const data = await ApiService.get('api/customer', params);
            setCustomers(data.customers || []);
            setTotalPages(data.totalPages || 1);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching customers:', error);
            setLoading(false);
        }
    }, [currentPage, sortField, sortOrder, search]);

    useEffect(() => {
        fetchCustomers();
    }, [fetchCustomers]);

    const handleSort = (field) => {
        const newSortOrder = (sortField === field && sortOrder === 'asc') ? 'desc' : 'asc';
        setSortField(field);
        setSortOrder(newSortOrder);
    };

    const handleDeleteCustomer = async (customerId) => {
        try {
            await ApiService.delete(`api/customer/${customerId}`);
            fetchCustomers();
        } catch (error) {
            console.error('Error marking customer as deleted:', error);
        }
    };

    return (
        <div className="container" style={{ fontSize: '0.85rem' }}>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h2 style={{ fontSize: '1.5rem' }}>Customer Management</h2>
                <Button size="sm" variant="primary" onClick={() => navigate('/admin/customers/create')}>
                    Create Customer
                </Button>
            </div>

            <Form className="mb-2" onSubmit={(e) => { e.preventDefault(); fetchCustomers(); }}>
                <Row>
                    <Col xs={9}>
                        <Form.Control
                            type="text"
                            placeholder="Search by name or email..."
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            size="sm"
                        />
                    </Col>
                    <Col xs={3}>
                        <Button type="submit" size="sm" variant="secondary" className="w-100">Search</Button>
                    </Col>
                </Row>
            </Form>

            {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '150px' }}>
                    <Spinner animation="border" size="sm" />
                </div>
            ) : (
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th onClick={() => handleSort('name')}>
                                Name {sortField === 'name' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
                            </th>
                            <th onClick={() => handleSort('email')}>
                                Email {sortField === 'email' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
                            </th>
                            <th>Address</th>
                            <th>Billing Address</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {customers.length > 0 ? (
                            customers.map((customer) => (
                                <tr key={customer.id}>
                                    <td>{customer.name}</td>
                                    <td>{customer.email}</td>
                                    <td>
                                        {customer.address.street1}, {customer.address.street2}, {customer.address.city}, {customer.address.stateOrProvince} {customer.address.postalCode}, {customer.address.country}
                                    </td>
                                    <td>
                                        {customer.billing_address.street1}, {customer.billing_address.street2}, {customer.billing_address.city}, {customer.billing_address.stateOrProvince} {customer.billing_address.postalCode}, {customer.billing_address.country}
                                    </td>
                                    <td>
                                        <div className="d-flex justify-content-start w-100">
                                            <Button
                                                variant="outline-primary"
                                                className="me-2"
                                                size="sm"
                                                onClick={() => navigate(`/admin/customers/${customer.id}`)}
                                            >
                                                <FontAwesomeIcon icon={faEdit} />
                                            </Button>
                                            <Button
                                                variant="outline-danger"
                                                size="sm"
                                                onClick={() => handleDeleteCustomer(customer.id)}
                                            >
                                                <FontAwesomeIcon icon={faTrashAlt} />
                                            </Button>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5" className="text-center">No customers found</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            )}

            <div className="d-flex justify-content-end">
                <Pagination size="sm">
                    <Pagination.Prev
                        onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                        disabled={currentPage === 1}
                    />
                    {[...Array(totalPages)].map((_, index) => (
                        <Pagination.Item
                            key={index + 1}
                            active={index + 1 === currentPage}
                            onClick={() => setCurrentPage(index + 1)}
                        >
                            {index + 1}
                        </Pagination.Item>
                    ))}
                    <Pagination.Next
                        onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                        disabled={currentPage === totalPages}
                    />
                </Pagination>
            </div>
        </div>
    );
};

export default CustomerManagement;
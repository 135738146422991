import React, { useState } from 'react';
import axios from 'axios';
import Papa from 'papaparse';

const RatesUpload = () => {
    const [file, setFile] = useState(null);
    const [name, setName] = useState('');
    const [carrier, setCarrier] = useState('');
    const [data, setData] = useState([]);
    const [zones, setZones] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const parseCsv = () => {
        if (file) {
            Papa.parse(file, {
                header: true,
                skipEmptyLines: true,
                complete: (result) => {
                    const headers = result.meta.fields.slice(1); // Skip the weight column for headers
                    const weights = result.data.map((row) => ({
                        weight: row[result.meta.fields[0]], // Use the first column for weights
                        rates: headers.map((zone) => ({
                            zone,
                            rate: row[zone]
                        }))
                    }));
    
                    setZones(headers);
                    setData(weights);
                },
                error: (error) => {
                    console.error('Error parsing CSV:', error);
                }
            });
        }
    };

    const handleSubmit = async () => {
        if (!name || !carrier) {
            alert('Please enter Name and Carrier!');
            return;
        }

        setLoading(true);
        try {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('name', name);
            formData.append('carrier', carrier);

            await axios.post('/api/rate/upload', formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });

            alert('CSV uploaded successfully!');
            setData([]);
        } catch (error) {
            console.error('Error uploading CSV:', error);
            alert('Failed to upload CSV');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container mt-5">
            <h2>CSV Uploader</h2>
            <div className="mb-3">
                <label>Name:</label>
                <input
                    type="text"
                    className="form-control"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </div>
            <div className="mb-3">
                <label>Carrier:</label>
                <input
                    type="text"
                    className="form-control"
                    value={carrier}
                    onChange={(e) => setCarrier(e.target.value)}
                />
            </div>
            <div className="mb-3">
                <input type="file" className="form-control" onChange={handleFileChange} />
            </div>
            <button className="btn btn-primary mb-3" onClick={parseCsv}>
                Preview CSV
            </button>
            {data.length > 0 && (
                <>
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>Weight</th>
                                {zones.map((zone) => (
                                    <th key={zone}>{zone}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((row, idx) => (
                                <tr key={idx}>
                                    <td>{row.weight}</td>
                                    {row.rates.map(({ zone, rate }) => (
                                        <td key={zone}>{rate}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <button
                        className="btn btn-success"
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                        {loading ? 'Uploading...' : 'Save to Database'}
                    </button>
                </>
            )}
        </div>
    );
};

export default RatesUpload;
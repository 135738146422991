import React, { useEffect, useState } from 'react';
import ApiService from '../services/ApiService.js';  // Importing ApiService
import { Form, Spinner } from 'react-bootstrap';  // Import Bootstrap components

const CustomerDropdown = ({ onChange, selectedCustomerId }) => {
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchCustomers = async () => {
            try {
                const data = await ApiService.get('api/customer/names');  // Fetch customer names from API
                setCustomers(data);
                setLoading(false);  // Set loading to false when data is fetched
            } catch (error) {
                console.error('Error fetching customer names:', error);
                setError('Error fetching customers.');
                setLoading(false);
            }
        };

        fetchCustomers();
    }, []);

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center">
                <Spinner animation="border" />
                <span className="ms-2">Loading customers...</span>
            </div>
        );  // Show a Bootstrap spinner and loading message
    }

    if (error) {
        return <p className="text-danger">{error}</p>;  // Show error message with Bootstrap's text-danger class
    }

    return (
        <Form.Group controlId="customerDropdown">
            <Form.Select
                value={selectedCustomerId || ''}
                onChange={(e) => onChange(e.target.value)}  // Pass selected customer ID to parent via onChange
            >
                <option value="" disabled>Select a customer</option>
                {customers.map((customer) => (
                    <option key={customer.id} value={customer.id}>
                        {customer.name}
                    </option>
                ))}
            </Form.Select>
        </Form.Group>
    );
};

export default CustomerDropdown;
import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Container } from 'react-bootstrap';
import ApiService from '../services/ApiService.js';

const Dashboard = () => {
    const [userCount, setUserCount] = useState(0);
    const [customerCount, setCustomerCount] = useState(0);
    const [integrationCount, setIntegrationCount] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const usersResponse = await ApiService.get('api/dashboard/users');
                setUserCount(usersResponse.data.totalUsers);

                const customersResponse = await ApiService.get('api/dashboard/customers');
                setCustomerCount(customersResponse.data.totalCustomers);

                const integrationsResponse = await ApiService.get('api/dashboard/integrations');
                setIntegrationCount(integrationsResponse.data.activeIntegrations);
            } catch (error) {
                console.error("Error fetching dashboard data", error);
            }
        };

        fetchData();
    }, []);

    return (
        <Container fluid className="mt-4">
            <h1>Admin Dashboard</h1>
            <Row className="my-4">
                <Col md={6} lg={3}>
                    <Card className="mb-4 shadow-sm">
                        <Card.Body>
                            <Card.Title>Total Users</Card.Title>
                            <Card.Text>{userCount}</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6} lg={3}>
                    <Card className="mb-4 shadow-sm">
                        <Card.Body>
                            <Card.Title>Total Customers</Card.Title>
                            <Card.Text>{customerCount}</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6} lg={3}>
                    <Card className="mb-4 shadow-sm">
                        <Card.Body>
                            <Card.Title>Active Integrations</Card.Title>
                            <Card.Text>{integrationCount}</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Dashboard;
import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import ApiService from '../services/ApiService.js';
import { Breadcrumb, Spinner, Container, Row, Col, Button, Card, Collapse } from 'react-bootstrap';
import { JSONTree } from 'react-json-tree';
import { FaClipboard, FaDownload, FaChevronUp, FaChevronDown } from 'react-icons/fa';
import clipboardCopy from 'clipboard-copy';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const theme = {
    scheme: 'monokai',
    base00: '#272822',
    base05: '#f8f8f2'
};

const ShipmentDetails = () => {
    const { trackingNumber } = useParams();
    const [shipment, setShipment] = useState(null);
    const [loading, setLoading] = useState(true);
    const [collapseState, setCollapseState] = useState({
        requestData: false, // Collapsed by default
        responseData: false, // Collapsed by default
        rateResponse: false, // Collapsed by default
    });

    useEffect(() => {
        const fetchShipmentDetails = async () => {
            try {
                const shipmentData = await ApiService.get(`api/shipment/${trackingNumber}`);
                setShipment(shipmentData);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching shipment details:', error);
                setLoading(false);
            }
        };

        fetchShipmentDetails();
    }, [trackingNumber]);

    const shouldExpandNodeInitially = () => true;

    const handleCopy = (jsonData, label) => {
        clipboardCopy(JSON.stringify(jsonData, null, 2));
        toast.success(`${label} copied to clipboard!`, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };

    const toggleCollapse = (key) => {
        setCollapseState((prev) => ({
            ...prev,
            [key]: !prev[key],
        }));
    };

    const handleDownloadLabel = async (trackingNumber) => {
        try {
            const response = await ApiService.request('GET', `api/shipment/${trackingNumber}/label`);
            const contentType = response.headers['content-type'];
            const mimeType = contentType.split(';')[0];
            const label = response.data;

            const byteCharacters = atob(label);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: contentType });

            const downloadUrl = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = downloadUrl;
            a.download = `label-${trackingNumber}.${mimeType.split('/')[1]}`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

            URL.revokeObjectURL(downloadUrl);
        } catch (err) {
            console.error('Error downloading label:', err);
            toast.error(`Failed to download label for ${trackingNumber}: ${err.message}`, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };

    return (
        <Container>
            <ToastContainer />

            <Row className="mb-3">
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin/shipments" }}>
                            Shipment Management
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Shipment Details</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>

            <h5 className="mb-3" style={{ fontSize: '1rem' }}>Shipment Details</h5>

            {loading ? (
                <Row className="justify-content-center align-items-center" style={{ height: '150px' }}>
                    <Spinner animation="border" size="sm" />
                </Row>
            ) : shipment ? (
                <div>
                    <p style={{ fontSize: '0.9rem' }}><strong>Tracking Number:</strong> {shipment.primary_tracking_number}</p>

                    <p style={{ fontSize: '0.9rem' }}>
                        <strong>Customer Name:</strong>{' '}
                        <Link to={`/admin/customers/${shipment.customer_id}`}>
                            {shipment.customer_name}
                        </Link>
                    </p>

                    <p style={{ fontSize: '0.9rem' }}>
                        <strong>Integration Name:</strong>{' '}
                        <Link to={`/admin/integrations/${shipment.integration_id}`}>
                            {shipment.integration_name}
                        </Link>
                    </p>

                    <p style={{ fontSize: '0.9rem' }}><strong>Created At:</strong> {new Date(shipment.created_at).toLocaleString()}</p>
                    <p style={{ fontSize: '0.9rem' }}>
                        <strong>Updated At:</strong> {new Date(shipment.updated_at).toLocaleString()}
                    </p>

                    <p style={{ fontSize: '0.9rem' }}><strong>Is Sandbox:</strong> {shipment.is_sandbox ? 'Yes' : 'No'}</p>

                    {shipment.voided_at && (
                        <p style={{ fontSize: '0.9rem' }}><strong>Voided At:</strong> {new Date(shipment.voided_at).toLocaleString()}</p>
                    )}

                    <h6 className="mt-3" style={{ fontSize: '0.9rem' }}>Tracking Numbers:</h6>
                    {shipment.all_tracking_numbers && shipment.all_tracking_numbers.length > 0 ? (
                        shipment.all_tracking_numbers.map((tn, index) => (
                            <p key={index}>
                                <Button variant="primary" size="sm" onClick={() => handleDownloadLabel(tn)}>
                                    <FaDownload /> Download Label for {tn}
                                </Button>
                            </p>
                        ))
                    ) : (
                        <p style={{ fontSize: '0.9rem' }}>No additional tracking numbers available.</p>
                    )}

                    {/* Existing Cards */}
                    <Card className="mb-3" style={{ fontSize: '0.9rem' }}>
                        <Card.Header className="d-flex justify-content-between align-items-center" onClick={() => toggleCollapse('requestData')} style={{ cursor: 'pointer' }}>
                            Request Data
                            <FaChevronDown className={`float-end ${collapseState.requestData ? 'rotate' : ''}`} />
                        </Card.Header>
                        <Collapse in={collapseState.requestData}>
                            <div>
                                <Card.Body style={{ fontSize: '0.85rem' }}>
                                    <JSONTree
                                        data={shipment.rkt_request}
                                        theme={theme}
                                        invertTheme={false}
                                        shouldExpandNodeInitially={shouldExpandNodeInitially}
                                    />
                                    <Button
                                        variant="link"
                                        size="sm"
                                        onClick={() => handleCopy(shipment.rkt_request, 'Request Data')}
                                    >
                                        <FaClipboard /> Copy
                                    </Button>
                                </Card.Body>
                            </div>
                        </Collapse>
                    </Card>

                    <Card className="mb-3" style={{ fontSize: '0.9rem' }}>
                        <Card.Header className="d-flex justify-content-between align-items-center" onClick={() => toggleCollapse('responseData')} style={{ cursor: 'pointer' }}>
                            Response Data
                            <FaChevronDown className={`float-end ${collapseState.responseData ? 'rotate' : ''}`} />
                        </Card.Header>
                        <Collapse in={collapseState.responseData}>
                            <div>
                                <Card.Body style={{ fontSize: '0.85rem' }}>
                                    <JSONTree
                                        data={shipment.integration_response}
                                        theme={theme}
                                        invertTheme={false}
                                        shouldExpandNodeInitially={shouldExpandNodeInitially}
                                    />
                                    <Button
                                        variant="link"
                                        size="sm"
                                        onClick={() => handleCopy(shipment.integration_response, 'Response Data')}
                                    >
                                        <FaClipboard /> Copy
                                    </Button>
                                </Card.Body>
                            </div>
                        </Collapse>
                    </Card>

                    <Card className="mb-3" style={{ fontSize: '0.9rem' }}>
                        <Card.Header className="d-flex justify-content-between align-items-center" onClick={() => toggleCollapse('rateResponse')} style={{ cursor: 'pointer' }}>
                            Rate Response
                            <FaChevronDown className={`float-end ${collapseState.rateResponse ? 'rotate' : ''}`} />
                        </Card.Header>
                        <Collapse in={collapseState.rateResponse}>
                            <div>
                                <Card.Body style={{ fontSize: '0.85rem' }}>
                                    <JSONTree
                                        data={shipment.rate}
                                        theme={theme}
                                        invertTheme={false}
                                        shouldExpandNodeInitially={shouldExpandNodeInitially}
                                    />
                                    <Button
                                        variant="link"
                                        size="sm"
                                        onClick={() => handleCopy(shipment.rate, 'Rate Response')}
                                    >
                                        <FaClipboard /> Copy
                                    </Button>
                                </Card.Body>
                            </div>
                        </Collapse>
                    </Card>
                </div>
            ) : (
                <p>Shipment not found</p>
            )}
        </Container>
    );
};

export default ShipmentDetails;
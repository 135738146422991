class IntegrationConfiguration {
    static details = {
        webship: {
            providerId: 'J3K8',
            type: 'webship',
            name: 'Webship',
            setupFields: {
                apiDetails: {
                    apiKey: { 
                        type: 'string', 
                        required: true, 
                        default: '', 
                        validation: { type: 'non-empty', maxLength: 128 },
                        description: 'API key for Webship authentication'
                    },
                    webshipCustomerId: { 
                        type: 'string', 
                        required: true, 
                        default: '', 
                        validation: { type: 'non-empty', maxLength: 64 },
                        description: 'Unique Webship customer identifier' 
                    }
                }
            },
            description: "Webship integration details",
        },
        amazon: {
            providerId: 'A4Z9',
            type: 'amazon',
            name: 'Amazon',
            setupFields: {
                shipFromAddress: {
                    name: { 
                        type: 'string', 
                        required: true, 
                        default: '', 
                        validation: { minLength: 1, maxLength: 50 },
                        description: 'Name of the sender or business at the shipping origin'
                    },
                    companyName: { 
                        type: 'string', 
                        required: false, 
                        default: '', 
                        validation: { maxLength: 50 },
                        description: 'Business or institution name associated with the sender' 
                    },
                    street1: { 
                        type: 'string', 
                        required: true, 
                        default: '', 
                        validation: { minLength: 1, maxLength: 60 },
                        description: 'Primary address line' 
                    },
                    street2: { 
                        type: 'string', 
                        required: false, 
                        default: '', 
                        validation: { minLength: 1, maxLength: 60 },
                        description: 'Secondary address line' 
                    },
                    street3: { 
                        type: 'string', 
                        required: false, 
                        default: '', 
                        validation: { minLength: 1, maxLength: 60 },
                        description: 'Additional address information' 
                    },
                    city: { 
                        type: 'string', 
                        required: true, 
                        default: '', 
                        validation: { maxLength: 50 },
                        description: 'City or town name' 
                    },
                    stateOrRegion: { 
                        type: 'string', 
                        required: false, 
                        default: '', 
                        validation: { maxLength: 50 },
                        description: 'State, region, or province name' 
                    },
                    postalCode: { 
                        type: 'string', 
                        required: true, 
                        default: '', 
                        validation: { type: 'regex', pattern: /^[A-Za-z0-9 -]{3,10}$/, maxLength: 10 },
                        description: 'Postal or ZIP code' 
                    },
                    countryCode: { 
                        type: 'string', 
                        required: true, 
                        default: '', 
                        validation: { type: 'regex', pattern: /^[A-Z]{2}$/ },
                        description: 'ISO 3166-1 alpha-2 country code' 
                    },
                    email: { 
                        type: 'string', 
                        required: false, 
                        default: '', 
                        validation: { maxLength: 64, type: 'email' },
                        description: 'Contact email for the shipping origin' 
                    },
                    phoneNumber: { 
                        type: 'string', 
                        required: false, 
                        default: '', 
                        validation: { minLength: 1, maxLength: 20, type: 'regex', pattern: /^[0-9]{10,15}$/ },
                        description: 'Contact phone number including country code' 
                    }
                },
                returnAddress: {
                    name: { 
                        type: 'string', 
                        required: true, 
                        default: '', 
                        validation: { minLength: 1, maxLength: 50 },
                        description: 'Return contact name' 
                    },
                    companyName: { 
                        type: 'string', 
                        required: false, 
                        default: '', 
                        validation: { maxLength: 50 },
                        description: 'Business name at return address' 
                    },
                    street1: { 
                        type: 'string', 
                        required: true, 
                        default: '', 
                        validation: { minLength: 1, maxLength: 60 },
                        description: 'Primary return address line' 
                    },
                    street2: { 
                        type: 'string', 
                        required: false, 
                        default: '', 
                        validation: { minLength: 1, maxLength: 60 },
                        description: 'Secondary return address line' 
                    },
                    street3: { 
                        type: 'string', 
                        required: false, 
                        default: '', 
                        validation: { minLength: 1, maxLength: 60 },
                        description: 'Additional return address information' 
                    },
                    city: { 
                        type: 'string', 
                        required: true, 
                        default: '', 
                        validation: { maxLength: 50 },
                        description: 'Return address city' 
                    },
                    stateOrRegion: { 
                        type: 'string', 
                        required: false, 
                        default: '', 
                        validation: { maxLength: 50 },
                        description: 'State or region at return address' 
                    },
                    postalCode: { 
                        type: 'string', 
                        required: true, 
                        default: '', 
                        validation: { type: 'regex', pattern: /^[A-Za-z0-9 -]{3,10}$/, maxLength: 10 },
                        description: 'Return address postal code' 
                    },
                    countryCode: { 
                        type: 'string', 
                        required: true, 
                        default: '', 
                        validation: { type: 'regex', pattern: /^[A-Z]{2}$/ },
                        description: 'Return address country code' 
                    },
                    email: { 
                        type: 'string', 
                        required: false, 
                        default: '', 
                        validation: { maxLength: 64, type: 'email' },
                        description: 'Return address contact email' 
                    },
                    phoneNumber: { 
                        type: 'string', 
                        required: false, 
                        default: '', 
                        validation: { minLength: 1, maxLength: 20, type: 'regex', pattern: /^[0-9]{10,15}$/ },
                        description: 'Return address contact phone number' 
                    }
                },
                contact: {
                    name: { 
                        type: 'string', 
                        required: true, 
                        default: '', 
                        validation: { maxLength: 50 },
                        description: 'Primary contact name' 
                    },
                    phoneNumber: { 
                        type: 'string', 
                        required: true, 
                        default: '', 
                        validation: { type: 'regex', pattern: /^[0-9]{10,15}$/, minLength: 10, maxLength: 15 },
                        description: 'Primary contact phone number' 
                    },
                    email: { 
                        type: 'string', 
                        required: true, 
                        default: '', 
                        validation: { type: 'email', maxLength: 64 },
                        description: 'Primary contact email' 
                    }
                },
                pickupInstructions: {
                    instructions: { 
                        type: 'string', 
                        required: false, 
                        default: '', 
                        validation: { maxLength: 200 },
                        description: 'Special instructions for package pickup' 
                    }
                },
                preferredPickupWindow: {
                    start: { 
                        type: 'string', 
                        required: false, 
                        default: '', 
                        validation: { type: 'iso-datetime' },
                        description: 'Preferred pickup start time in ISO format' 
                    },
                    end: { 
                        type: 'string', 
                        required: false, 
                        default: '', 
                        validation: { type: 'iso-datetime' },
                        description: 'Preferred pickup end time in ISO format' 
                    }
                },
                averages: {
                    packageCount: { 
                        type: 'number', 
                        required: false, 
                        default: 0, 
                        validation: { type: 'positive-integer', min: 1, max: 100 },
                        description: 'Average number of packages per shipment' 
                    },
                    shipmentWeight: { 
                        type: 'number', 
                        required: false, 
                        default: 0, 
                        validation: { type: 'positive-float', min: 0.01, max: 1000 },
                        description: 'Average shipment weight in pounds or kilograms' 
                    },
                    shipmentDimensions: {
                        length: { 
                            type: 'number', 
                            required: false, 
                            default: 0, 
                            validation: { type: 'positive-float', min: 1, max: 200 },
                            description: 'Average shipment length' 
                        },
                        width: { 
                            type: 'number', 
                            required: false, 
                            default: 0, 
                            validation: { type: 'positive-float', min: 1, max: 200 },
                            description: 'Average shipment width' 
                        },
                        height: { 
                            type: 'number', 
                            required: false, 
                            default: 0, 
                            validation: { type: 'positive-float', min: 1, max: 200 },
                            description: 'Average shipment height' 
                        }
                    }
                }
            },
            description: "Amazon integration details"
        }
    };

    static getIntegrationByKey(key) {
        return this.details[key] || null;
    }

    static getIntegrationByProviderId(providerId) {
        const entry = Object.entries(this.details).find(
            ([, detail]) => detail.providerId === providerId
        );
        return entry ? entry[1] : null;
    }

    static getAllKeys() {
        return Object.keys(this.details);
    }
}

export default IntegrationConfiguration;
import React, { useEffect, useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ApiService from '../services/ApiService.js';
import { Table, Button, Pagination, Spinner, Form, Row, Col } from 'react-bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';

const ShipmentManagement = () => {
    const [shipments, setShipments] = useState([]);
    const [loading, setLoading] = useState(true);

    // Sorting, pagination, and search
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [sortField, setSortField] = useState('created_at');
    const [sortOrder, setSortOrder] = useState('desc');
    const [search, setSearch] = useState('');
    const pageSize = 10;

    const navigate = useNavigate();

    const fetchShipments = useCallback(async () => {
        setLoading(true);
        try {
            const params = { page: currentPage, pageSize, sortField, sortOrder, search };
            const data = await ApiService.get('api/shipment', params);
            setShipments(data.shipments || []);
            setTotalPages(data.totalPages || 1);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching shipments:', error);
            setLoading(false);
        }
    }, [currentPage, sortField, sortOrder, search]);

    useEffect(() => {
        fetchShipments();
    }, [fetchShipments]);

    const handleSort = (field) => {
        const newSortOrder = (sortField === field && sortOrder === 'asc') ? 'desc' : 'asc';
        setSortField(field);
        setSortOrder(newSortOrder);
    };

    return (
        <div className="container">
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h5 className="mb-0" style={{ fontSize: '1rem' }}>Shipment Management</h5>
                {/* Uncomment if needed */}
                {/* <Button variant="primary" size="sm" onClick={() => navigate('/admin/shipments/create')}>
                    Create Shipment
                </Button> */}
            </div>

            {/* Search Form */}
            <Form className="mb-2" onSubmit={(e) => { e.preventDefault(); fetchShipments(); }}>
                <Row>
                    <Col xs={10}>
                        <Form.Control
                            type="text"
                            size="sm"
                            placeholder="Search by tracking number, customer, or integration..."
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </Col>
                    <Col xs={2}>
                        <Button type="submit" variant="secondary" size="sm" className="w-100">Search</Button>
                    </Col>
                </Row>
            </Form>

            {/* Loading Spinner */}
            {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '150px' }}>
                    <Spinner animation="border" size="sm" />
                </div>
            ) : (
                <Table striped bordered hover size="sm" responsive>
                    <thead>
                        <tr style={{ fontSize: '0.85rem' }}>
                            <th onClick={() => handleSort('primary_tracking_number')}>
                                Tracking Number {sortField === 'primary_tracking_number' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
                            </th>
                            <th onClick={() => handleSort('carrier')}>
                                Carrier {sortField === 'carrier' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
                            </th>
                            <th onClick={() => handleSort('customer_name')}>
                                Customer {sortField === 'customer_name' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
                            </th>
                            <th onClick={() => handleSort('integration_name')}>
                                Integration {sortField === 'integration_name' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
                            </th>
                            <th onClick={() => handleSort('created_at')}>
                                Created At {sortField === 'created_at' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
                            </th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {shipments.length > 0 ? (
                            shipments.map((shipment) => (
                                <tr key={shipment.id} style={{ fontSize: '0.85rem' }}>
                                    <td>{shipment.primary_tracking_number}</td>
                                    <td className="text-uppercase">{shipment.carrier}</td>
                                    <td>
                                        <Link to={`/admin/customers/${shipment.customer_id}`}>
                                            {shipment.customer_name}
                                        </Link>
                                    </td>
                                    <td>
                                        <Link to={`/admin/integrations/${shipment.integration_id}`}>
                                            {shipment.integration_name}
                                        </Link>
                                    </td>
                                    <td>{new Date(shipment.created_at).toLocaleString()}</td>
                                    <td>
                                        <Button
                                            variant="outline-primary"
                                            size="sm"
                                            onClick={() => navigate(`/admin/shipments/${shipment.primary_tracking_number}`)}
                                        >
                                            <i className="bi bi-eye-fill"></i>
                                        </Button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="6" className="text-center">No shipments found</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            )}

            {/* Pagination */}
            <div className="d-flex justify-content-end mt-2">
                <Pagination size="sm">
                    <Pagination.Prev
                        onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                        disabled={currentPage === 1}
                    />
                    {[...Array(totalPages)].map((_, index) => (
                        <Pagination.Item
                            key={index + 1}
                            active={index + 1 === currentPage}
                            onClick={() => setCurrentPage(index + 1)}
                        >
                            {index + 1}
                        </Pagination.Item>
                    ))}
                    <Pagination.Next
                        onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                        disabled={currentPage === totalPages}
                    />
                </Pagination>
            </div>
        </div>
    );
};

export default ShipmentManagement;